// bootstrap
import 'bootstrap';

// alpine js
import Alpine from 'alpinejs';
window.Alpine = Alpine;

// alpine modules
/* fixed navbar if desired
import header from './alpine-js/header';
Alpine.data('header', header);
*/

Alpine.start();

// lazysizes
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';